<template>
    <div id="cases-landing-page">
        <h1>Case Dashboard</h1>
        <div class="auth" v-if="authenticated">
            <div class="md-layout md-gutter md-alignment-top-center case-table">
                <div class="md-layout-item md-size-90">
                        <md-table v-model="searched" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="itemSort" md-card>
                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                  <div>
                                    <md-field md-clearable >
                                        <md-input placeholder="Search for text..." v-model="search" />
                                    </md-field>
                                    <div class="filter-info">showing {{searched.length}} of
                                      <span v-if="showClosed">{{totalCases}}</span>
                                      <span v-if="!showClosed">{{totalCasesActive}}</span>
                                    </div>
                                  </div>
                                </div>

                                <h1 class="md-title">Cases</h1>
                                <div class="num-cases">
                                  <div>Open Cases: {{totalCasesActive}}</div>
                                  <div v-if="totalCases > 0">Total Cases: {{totalCases}}</div>
                                  <div v-if="!loaded">Cases Loaded: {{numCasesLoaded}}
                                    <md-progress-spinner md-mode="indeterminate" :md-diameter="20"></md-progress-spinner>
                                  </div>
                                </div>

                              <div class="md-toolbar-section-end">
                                    <div style="display:block" >
                                        <md-checkbox v-model="showClosed" value="1" v-on:change="toggleShowClosed()" >show closed cases</md-checkbox>
                                    </div>
                                    <md-button class="md-primary md-raised" @click="showColumnSelector = true">
                                        <md-icon>table_chart</md-icon>
                                        <md-tooltip>Select Columns</md-tooltip>
                                    </md-button>
                                </div>
                            </md-table-toolbar>

                            <md-table-empty-state v-if="!loaded"
                                                  md-label="Loading"
                                                  :md-description="`Loading Case Data from Interfolio`">
                                    <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                            </md-table-empty-state>

                            <md-table-empty-state v-else md-label="No Cases found">
                                    <p v-if="search">
                                        No cases found for this query: {{search}} . Try a different search term.
                                    </p>
                                    <p v-else>
                                        You do not have any cases in your queue.
                                    </p>
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell
                                        md-label="Name"
                                        md-sort-by="lastname"
                                        v-if="tableColumnsDisplayed.name" >
                                    <router-link :to="{ name: 'caseDetail', params: {caseId: item.id } }">
                                        {{ getName(item) }}
                                        <i class="md-icon md-icon-font inline-header-icon md-theme-default">open_in_new</i>
                                    </router-link>
                                </md-table-cell>


                                <md-table-cell
                                        v-if="tableColumnsDisplayed.department"
                                        md-label="Department/Unit"
                                        md-sort-by="unit_name" >
                                    {{ item.unit_name.replace('School of Arts and Sciences - ', '') }}
                                </md-table-cell>


                                <md-table-cell
                                        md-label="Current Workflow Step"
                                        md-sort-by="current_workflow_step_name"
                                        v-if="tableColumnsDisplayed.currentWorkflowStep" >
                                    <div v-if='item.current_workflow_step_name == null || item.current_workflow_step_name == "Case Kickoff Integration"'>
                                        <md-button v-if="item.closed == false" @click="handleSaveCase(item.id)" class="md-raised md-primary">
                                            Kickoff Case
                                        </md-button>
                                        <span v-else>[closed]</span>
                                    </div>
                                    <div v-else>
                                        {{ item.current_workflow_step_name }}
                                    </div>
                                </md-table-cell>


                                <md-table-cell
                                        v-if="tableColumnsDisplayed.created"
                                        md-label="Created"
                                        md-sort-by="created_date" >
                                    {{item.created_date.substr(0,10)}}
                                </md-table-cell>

                                <md-table-cell
                                        v-if="tableColumnsDisplayed.caseOpen"
                                        md-label="Case Open"
                                        md-sort-by="closed">
                                    <span v-if="item.closed">No</span>
                                    <span v-else>Yes</span>
                                </md-table-cell>

                                <md-table-cell
                                        v-if="tableColumnsDisplayed.actionType"
                                        md-label="Action"
                                        md-sort-by="actionType">
                                    {{item.actionType}}
                                </md-table-cell>

                                <md-table-cell
                                        v-if="tableColumnsDisplayed.title"
                                        md-label="Title"
                                        md-sort-by="title">
                                    {{item.title}}
                                </md-table-cell>

                                <md-table-cell
                                        v-if="tableColumnsDisplayed.startTerm"
                                        md-label="Start Term"
                                        md-sort-by="startTerm">
                                    {{item.startTerm}}
                                </md-table-cell>

                                <md-table-cell
                                        v-if="tableColumnsDisplayed.endTerm"
                                        md-label="End Term"
                                        md-sort-by="endTerm">
                                    {{item.endTerm}}
                                </md-table-cell>


                                <md-table-cell
                                        v-if="tableColumnsDisplayed.lps"
                                        md-label="LPS"
                                        md-sort-by="lps">
                                    {{item.lps}}
                                </md-table-cell>

                                <md-table-cell
                                        v-if="tableColumnsDisplayed.courseInfo"
                                        md-label="Course Info">
                                    <span class="course-info">{{item.courseId1}}</span>
                                    <span class="course-info">{{item.courseId2}}</span>
                                    <span class="course-info">{{item.courseId3}}</span>
                                    <span class="course-info">{{item.courseAdditionalInfo}}</span>
                                </md-table-cell>
                                <md-table-cell
                                    v-if="tableColumnsDisplayed.startDate"
                                    md-label="Start Date"
                                    md-sort-by="startDate" >
                                  <span v-if="item.startDate">{{item.startDate.substr(0,10)}}</span>
                                </md-table-cell>
                                <md-table-cell
                                    v-if="tableColumnsDisplayed.endDate"
                                    md-label="End Date"
                                    md-sort-by="endDate" >
                                  <span v-if="item.endDate">{{item.endDate.substr(0,10)}}</span>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
            </div>
        </div>
        <div id="create-new-warning-casedash">
            <md-dialog-confirm
              :md-active.sync="showCreateNewDialog"
              md-title="Warning: You have an open case"
              md-content="You currently have an incomplete case open. Would you like to delete that case and start a new one?"
              md-confirm-text="Clear & Create New"
              md-cancel-text="Continue Working on Open Case"
              @md-cancel="caseRouter()"
              @md-confirm="caseRouter(true)" />
        </div>

        <md-dialog :md-active.sync="showColumnSelector">
            <md-dialog-title>Select Data Columns to display</md-dialog-title>

            <md-dialog-content>
                <md-checkbox v-model="tableColumnsDisplayed.name" >Candidate Name</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.department" >Department</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.currentWorkflowStep" >Current Workflow Step</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.caseOpen" >Case Open</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.created" >Created Date</md-checkbox>
                <md-divider></md-divider>
                <div>The following columns may load slower and the information may be delayed for up to 4 hours</div>
                <md-checkbox v-model="tableColumnsDisplayed.actionType" >Action Type</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.title" >Title</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.lps" >LPS</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.startTerm" >Start Term</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.endTerm" >End Term</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.courseInfo" >Course Info</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.startDate" >Start Date</md-checkbox>
                <md-checkbox v-model="tableColumnsDisplayed.endDate" >End Date</md-checkbox>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-raised md-primary" @click="showColumnSelector = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
  import {LOAD_STATUS } from "../store/store-utils"

  const toLower = text => {
    return text.toString().toLowerCase();
  }

  const searchByText = (items, term, showClosed) => {
    return items.filter(item => {
      if(item.closed && !showClosed) return false;
      if(!term) return true;
      return toLower(JSON.stringify(item)).includes(toLower(term))
    });
  };

  const customSort = (items, field, direction) => {
      return items.sort((a, b) => {
        let val1 = a[field] ? a[field] : "zzzz";
        let val2 = b[field] ? b[field] : "zzzz";

        if(typeof a[field] === "boolean") {
          val1 = a[field] ? '1' : '0';
          val2 = b[field] ? '1' : '0';
        }
        if(field === "unit_name") {
          val1 = val1.replace('School of Arts and Sciences - ', '');
          val2 = val2.replace('School of Arts and Sciences - ', '');
        }

        if (direction === 'desc') {
          return val2.localeCompare(val1)
        }
        return val1.localeCompare(val2)

      })
    };

  export default {
    name: 'UserCases',
    data: function()  {
      return {
        currentSort: "lastname",
        currentSortOrder: "asc",
        search: null,
        loginUrl:  process.env.VUE_APP_SIGNIN_URL,
        showCreateNewDialog: false,
        kickableCaseId: null,
        showClosed: false,
        showColumnSelector: false,
        tableColumnsDisplayed: {
          name: true,
          department: true,
          currentWorkflowStep: true,
          created: false,
          caseOpen: true,
          actionType: false,
          title: false,
          startTerm: false,
          endTerm: false,
          lps: false,
          courseInfo: false,
          startDate: false,
          endDate: false,
        }
      }
    },
    computed: {
      authenticated() {
        return this.$store.getters['user/authenticated']
      },
      searched: {
        get: function() {
          let cases = this.$store.getters['interfolio/casesWithLecturerInfo'];
          return customSort(searchByText(cases, this.search, this.showClosed), this.currentSort, this.currentSortOrder);
        },
        set: function() {
          //do nothing
        }
      },
      loaded() {
        return this.$store.getters['interfolio/caseLoadStatus'] === LOAD_STATUS.LOADED;
      },
      caseLoadStatus() {
        return this.$store.getters['interfolio/caseLoadStatus'];
      },
      totalCases() {
        return this.$store.getters['interfolio/totalCases'];
      },
      totalCasesActive() {
        return this.$store.getters['interfolio/totalCasesActive'];
      },
      numCasesLoaded() {
        return this.$store.getters['interfolio/numCasesLoaded'];
      },
      dataIncludesClosedCases() {
        return this.$store.getters['interfolio/dataIncludesClosedCases'];
      }
    },
    methods: {
      getName(item) {
        if(item.lastname === null && item.firstname === null) {
          return '[unidentified]';
        }
        return item.lastname + ', ' + item.firstname;
      },
      itemSort(value) {
        return customSort(value, this.currentSort, this.currentSortOrder);
      },
      handleSaveCase(caseId) {
          this.kickableCaseId = caseId;
          if (localStorage.getItem('caseKickoffForm')){
              this.showCreateNewDialog = true;
          } else {
              this.showCreateNewDialog = false;
              this.caseRouter(true);
          }
      },
      caseRouter(newCase = false) {
          if (newCase) {
              this.$emit('newCaseRouter', true, this.kickableCaseId);
          } else {
              this.$emit('newCaseRouter');
          }
      },
      signIn: function () {
        this.$store.dispatch('user/signIn');
      },
      toggleShowClosed() {
        if(this.showClosed && !this.$store.getters['interfolio/dataIncludesClosedCases']) {
          this.$store.dispatch("interfolio/loadCases");
        }
      }
  },
}
</script>

<style>
    .case-table .md-table-head {
        text-align: center;
    }

    html.md-theme-default .case-table a:hover {
        text-decoration: none;
        color: #0000FF;
    }
    .case-table a:hover {
        text-decoration: none;
        color: #0000FF;
    }
    .kickoff-button {
        background-color: green !important;
        color: white !important;
    }
    a:hover .md-icon.md-theme-default.md-icon-font {
        color: #0000FF;
        text-decoration: none;
    }
    #cases-landing-page .md-toolbar-section-end {
        flex-flow: row;
        align-items: flex-start;
    }
    #cases-landing-page .md-checkbox-label {
       padding-left: 3px;
        white-space: nowrap;
    }
    .course-info {
        margin: 0px 5px;
    }
    .filter-info {
      text-align: left;
    }
</style>
